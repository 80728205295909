import {
  IAdditionalBookingRequirements,
  ISchedule,
} from "app/services/client/client.types";
import { IWidgetTheme, wrapperStyleType } from "app/models";
import {loadStatus} from "app/types/common.types";
import {
  IScheduleService,
  IScheduleTime,
  ISectionOrder
} from "shared-types/index";

export interface IDispatchFromProps {
  handleCoversCountChange: (coversCount: number) => void;
  handleServiceChange: (id: string) => Promise<IScheduleService>;
  handleSectionChange: (id: string) => void;
  handleCoversCountPending: () => void;
  handleVerificationAcceptance: () => void;
  handleVenueTimeChange: (time: IScheduleTime) => void;
  setBookedBy: (status: boolean) => void;
}

export interface IStateFromProps {
  wrapperStyle: wrapperStyleType;
  hasMoreThan1Section: boolean;
  hasAtLeast1Section: boolean;
  coversAlertMessage: string;
  sectionAlertMessage: string;
  serviceAlertMessage: string;
  hasTimesAlertMessage: boolean;
  theme: IWidgetTheme;
  maxPeoplePerBooking: number;
  minPaxPerBooking:number;
  scheduleMessage: string;
  scheduleLoading: boolean;
  filteredServices: IScheduleService[];
  activeService: IScheduleService;
  filteredSections: ISectionOrder[];
  showMenuOptions: boolean;
  activeSectionId: string;
  getMaxBreachMessage: (maxPeoplePerBooking: number) => string;
  minPaxPerBookingMessage: string;
  canCustomersChooseSection: boolean;
  coversPrefill: number;
  isPreviewMode: boolean;
  viewTime: string;
  hasMenuOptionAlert: boolean;
  isStandbyMode: boolean;
  noTablesAvailable: boolean;
  isEditMode: boolean;
  haveAcceptedVerification?: boolean;
  additionalBookingRequirements?: IAdditionalBookingRequirements;
  enableNextAvailableBooking?: boolean;
  allScheduleLoadStatus: loadStatus;
  allVenueTimes: IScheduleTime[];
  allVenueSchedule: ISchedule[];
  enableGAW: boolean;
  allVenueApiCall: boolean;
  scheduleLoadStatus: loadStatus;
  emptyTimesMessage: string;
  shouldShowStandbyButton: boolean;
  hasValidTimes: boolean;
  standbyExhausted: boolean;
  isVenueOpen: boolean;
  closedVenueService: IScheduleService;
  hasMinimumPax?:boolean;
  enableNoTableMessage:boolean;
  selectedTimeForOtherVenues: IScheduleTime;
  expandedGAW: boolean;
  isBookedBy: boolean;
  enableBookedByOnWidget: boolean
}


export interface IState {
  coversInputOpen: boolean;
  coversInputOpenKeepOpen: boolean;
  servicesSelectorOpen: boolean;
  sectionsSelectorOpen: boolean;
  bookingOptionsSelectorOpen: boolean;
  timesSelectorOpen: boolean;
  gawSelectorOpen: boolean;
  coversCount: number;
  // coversPrefill: number;
  displayServices: boolean;
  displayTimes: boolean;
  rangeMax: number;
  rangeMaxChangeMessage: string;
  displayVerificationPanel: boolean;
  displayNextAvailableBookingTimePanel: boolean;
  displayGAW?: boolean;
  isTimeSlotSelected: boolean
}

export enum expandPanelType {
  covers = 1,
  services = 2,
  sections = 3,
  bookingOptions = 4,
  times = 5,
  verification = 6,
  gaw = 7,
  sbl = 8
}

export interface IVerificationExpansionPanelProps {
  onPanelExpansion: (type: expandPanelType) => void;
  onVerificationAcceptance: () => void;
  additionalBookingRequirements: IAdditionalBookingRequirements;
}

export interface ITimePickerExpansionPanelProps {
  viewTime: string;
  isLandscape: boolean;
  isStandbyMode: boolean;
  hasMoreThan1Section: boolean;
  timesSelectorOpen: boolean;
  handleExpansionPanelChange: (type: expandPanelType, typeToLeaveOpen?: expandPanelType) => void;
  showDivider: boolean;
  handleTimeSlotChange?: () => void;
  handleCancelStandby?: () => void;
}

export interface IGAWExpansionPanelProps {
  allScheduleLoadStatus: loadStatus;
  allVenueTimes: IScheduleTime[];
  allVenueSchedule: ISchedule[];
  enableGAW: boolean;
  allVenueApiCall: boolean;
  theme: IWidgetTheme;
  isEditMode: boolean;
  handleVenueTimeChange: (time: IScheduleTime) => void;
  nextAvailableBookingTimeMessage?: string;
  shouldShowStandbyButton: boolean;
  activeService: IScheduleService;
  standbyExhausted: boolean;
  isVenueOpen: boolean;
  closedVenueService: IScheduleService;
  selectedTimeForOtherVenues: IScheduleTime;
}
