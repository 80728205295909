import {Dispatch} from "redux";
import {IRootState} from "app/reducers";
import {appLoadCompleteSuccess, showProcessingOverlayLoader} from "app/actions/setup/helpers";
import {IBooking, IBookingOutgoing, IBookingResponseData} from "app/services/booking/booking.types";
import {UtilsService} from "app/services/utils/utils.service";
import {MenuOptionsService} from "app/services/menuOptions/menuOptions.service";
import {getSectionAny} from "app/components/SectionSelector/types";
import appValues from "app/constants/appValues";
import {IAppSettings, IVenue} from "app/models";
import {Observable, of} from "rxjs";
import {IResponse} from "app/containers/App/types";
import {ClientService} from "app/services/client/client.service";
import {first, switchMap} from "rxjs/operators";
import {
  sendBookingAnalytics, sendErrorSavingAnalytics,
  sendSessionTimeoutAnalytics,
  sendTimeExpiredAnalytics
} from "app/actions/booking/analyticsHelpers";
import {getMenuOptionIds, hideUpsellPopup} from "app/actions/booking/helpers";
import {RouteService} from "app/services/route/route.service";
import {ROUTE_NAMES} from "app/services/route/route.types";
import {SessionService} from "app/services/session/session.service";
import {ISessionTime} from "app/services/session/session.types";
import {IAction, IActionGen, loadStatus} from "app/types/common.types";
import {TimeFilterService} from "app/services/timeFilter/timeFilter.service";
import {ISchedule} from "app/services/client/client.types";
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {IErrorResponse, IScheduleTime, ISectionOrder, bookingErrorType} from "shared-types/index";

const NS = 'SaveBookingActions';

export const saveBooking = (dispatch: Dispatch, getState: () => IRootState): Promise<void> => {
  return new Promise(resolve => {
    const state = getState() as IRootState;

    showProcessingOverlayLoader(dispatch, 'Saving booking');
    hideUpsellPopup(dispatch, true)

    const {booking, savedBooking, activeService, activeVenue, appSettings} = state.widget;
    const activeSection = state.widget.activeSection || getSectionAny();
    const {utcTime} = booking;

    /**
     * We do a more thorough check against available times in the schedule, which involves
     * loading the schedule again
     */
    getLatestTimes(booking, activeService.id, (activeVenue as IVenue).id, activeSection, utcTime, !!savedBooking)
      .subscribe((latestTimes: string[]) => {

        // if the time exists in the schedule allow it to proceed with save
        if (latestTimes.indexOf(utcTime) !== -1) {
          saveOrUpdateBooking(dispatch, getState, resolve);
        } else {
          // otherwise we block the save and show error page
          sendTimeExpiredAnalytics(activeVenue);
          RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue).then(() => {
            // will be null if there is no error (eg expired)
            dispatch({type: BookingActionsTypes.TIME_EXPIRED, payload: utcTime} as IActionGen<string>);
            appLoadCompleteSuccess(dispatch);
            resolve();
          });
        }
      }, err => {
        console.warn(NS, 'getSchedule', 'error', err.response);
        if (err.response?.status === 503) {
          dispatch({type: BookingActionsTypes.SERVICE_SCHEDULE_FAILED, payload: loadStatus.failedTemp} as IAction);
          appLoadCompleteSuccess(dispatch);
        } else {
          sendErrorSavingAnalytics(activeVenue);
          RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue)
            .then(() => {
              dispatch({type: BookingActionsTypes.SAVE_BOOKING_FAIL, payload: err.response});
              appLoadCompleteSuccess(dispatch);
              resolve();
            });
        }

      });
  });
}

function saveOrUpdateBooking(dispatch: Dispatch, getState: () => IRootState, resolve: () => void) {
  const state = getState() as IRootState;
  const {booking, activeService, activeVenue, activeSection, savedBooking, appSettings} = state.widget;
  const {customer, selectedMenuOptions, covers, tags, utcTime, sectionId, isBookedBy, bookedBy} = booking as IBooking;

  let bookingOutgoing: IBookingOutgoing = {
    customer: UtilsService.omitFalseyProps(customer),
    // time: moment(utcTime).subtract(15, 'minutes').toISOString(), // just for debugging old times
    time: utcTime,
    notes: customer.notes,
    selectedMenuOptions: MenuOptionsService.getFlatExtras(selectedMenuOptions),
    numOfPeople: covers,
    sectionId: sectionId || 'all',
    hasCustomerNotes: booking.hasCustomerNotes,
    hasManagerNotes: booking.hasManagerNotes,
    service: {
      id: activeService.id,
      duration: activeService.duration || appValues.DEFAULT_SERVICE_DURATION,
      serviceType: activeService.serviceType,
      name: activeService.name
    },
    tags,
    bookedBy: isBookedBy ? bookedBy : null
  };

  bookingOutgoing = UtilsService.omitFalseyProps(bookingOutgoing);

  // Guarantee not post customer note
  if(bookingOutgoing && bookingOutgoing.customer)
  {
    bookingOutgoing.customer.notes = "";
  }

  const venueId: number = (activeVenue as IVenue).id;

  const saveOrUpdate$: Observable<IResponse<IBookingResponseData>> = savedBooking
    ? ClientService.updateBooking(savedBooking.bookingId, bookingOutgoing, venueId)
    : ClientService.saveBooking(bookingOutgoing, venueId);

  saveOrUpdate$
    .pipe(
      first(),
      switchMap((response: IResponse<IBookingResponseData>) => {

        const success = () => {
          console.log(NS, 'Save booking response.data', response.data);

          sendBookingAnalytics(activeVenue, booking, activeService, activeSection || getSectionAny());
          dispatch({
            type: BookingActionsTypes.SAVE_BOOKING_SUCCESS,
            payload: response.data
          } as IActionGen<IBookingResponseData>)
          appLoadCompleteSuccess(dispatch);
        }

        const routeToPayments$: Observable<boolean> = new Observable<boolean>(observer => {
          BookingOptionsActionsNS.updateCachedMenuOptionDetails(getMenuOptionIds(selectedMenuOptions), true)(dispatch, getState)
            .then(() => {
              success();
              RouteService.routeTo(ROUTE_NAMES.PAYMENTS, dispatch, appSettings, activeVenue);
              observer.next(true);
              observer.complete();
            });
        });

        const routeToThankyou$: Observable<boolean> = new Observable<boolean>(observer => {
          RouteService.routeTo(ROUTE_NAMES.THANK_YOU, dispatch, appSettings, activeVenue)
            .then(() => {
              success();
              observer.next(false);
              observer.complete();
            });
        });

        return response.data.paymentPending ? routeToPayments$ : routeToThankyou$;
      })
    )
    .subscribe((doStartSession: boolean) => {

      if (doStartSession) {
        startSession(dispatch, activeVenue as IVenue, appSettings, resolve);
        resolve();
      } else {
        resolve();
      }

    }, ({response}: { response: IErrorResponse }) => {
      RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue).then(() => {

        console.warn(NS, 'saveBooking fail', response)

        dispatch({type: BookingActionsTypes.SAVE_BOOKING_FAIL, payload: response});
        appLoadCompleteSuccess(dispatch);
        resolve();
      });
    });
}

// starts long running subscription
function startSession(dispatch: Dispatch, activeVenue: IVenue, appSettings: IAppSettings, resolve: () => void) {
  SessionService.startSession()
    .subscribe(({isActive, viewTime}: ISessionTime) => {
      if (viewTime) {
        dispatch({
          type: BookingActionsTypes.SESSION_REMAINING_TIME_UPDATED,
          payload: {isActive, viewTime}
        } as IActionGen<ISessionTime>);
      } else {
        sendSessionTimeoutAnalytics(activeVenue);
        RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue).then(() => {
          // will be null if there is no error (eg expired)
          dispatch({
            type: BookingActionsTypes.SESSION_FINISHED,
            payload: bookingErrorType.bookingExpiredBeforePayment
          } as IActionGen<bookingErrorType>);
          resolve();
        });
      }
    });
}

function getLatestTimes(
  booking: IBooking,
  activeServiceId: string,
  activeVenueId: number,
  activeSection: ISectionOrder,
  utcTime: string,
  passSavedBookingId: boolean,
): Observable<string[]> {
  return ClientService.getSchedule(booking.moment, booking.covers, activeVenueId, passSavedBookingId ? booking._id : null)
    .pipe(
      first(),
      switchMap((value: ISchedule) => {
        const freshActiveService = value.services.find(({id}) => id === activeServiceId);

        return of(
          TimeFilterService.getFilteredTimesNonMutated(freshActiveService.times, true, activeSection, utcTime)
            .filter(t => !t.isDisabled) // @todo: check if we need to remove isBlocked times as well (from overlapping events)
            .map(t => t.time)
        )
      })
    );
}

function validateSectionStateForBookingTime(bookingTime: IScheduleTime, bookingSectionId: string): void {
  bookingTime.sections.some(sec => {
    if (sec.id === bookingSectionId) {
      sec.sectionState = true;
      return true;
    }
    return false;
  });
}
